import { ImobiliariaType } from "../shared/types/imobiliaria.types";
import { ImoveisInfo } from "../shared/types/imoveis.type";
import { LeadsInfo } from "../shared/types/leads.type";
import { UserType } from "../shared/types/user.types";

export const chartInitialValues = { labels: [], values: [] };

export const leadsInitialValues: LeadsInfo =  {
    leads: [],
    leadsCount: 0,
    chartByDate: chartInitialValues,
    chartByOrigin: chartInitialValues,
    chartByBairro: chartInitialValues,
    leadsCurrentMonth: [],
    leadsLastAccessCount: 0,
    leadsCurrentMonthCount: 0,
    rangeDate: {initialDate:'', lastDate:''},
};

export const imoveisInitialValues: ImoveisInfo = {
    imoveis: [],
    imoveisPublicados: [],
    imoveisDespublicados: [],
    venda: [],
    aluguel: [],
    temporada: [],
    lancamentos: [],
    avulsosPublicados: [],
    emCondominio: [],
    foraDeCondominio: [],
    maisVisualizados: [],
    bairrosMaisVisualizados: [],
    condEmpreendimento: [],
    condEmpreendimentoPublicados: [],
    condEmpreendimentoDespublicados: [],
    empreendimentos: {
        publicados: [],
        despublicados: []
    },
    condominios: {
        publicados: [],
        despublicados: []
    },
    chartByTipo: chartInitialValues,
    chartByVenda: chartInitialValues,
    chartByAluguel: chartInitialValues,
    chartByTemporada: chartInitialValues,
}

export const userInitialValues: UserType = {
    lastAccess: "",
    permissions: {
      showAvulsos: true,
      showCondominios: true,
      showLeads: true,
    }
}

export const imobiliariaInitialValues: ImobiliariaType = {
    isMidasWeb: false,
}
