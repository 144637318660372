import dashboardStore from "../../../store/dashboardStore";
import { CardImovel001 } from "../CardImovel001";
import { Container } from "./styles";

export default function ImoveisGrid() {
  const { user: { permissions }, imoveisInfo: { 
    imoveisPublicados, 
    imoveisDespublicados,
    empreendimentos,
    condominios,
  }} = dashboardStore();

  return (
    <Container data-testid='imoveisGridId'>
      { permissions.showAvulsos && (<CardImovel001 
        titulo="Imóveis" 
        subTitulo="(venda, aluguel e temporada)"
        publicados={imoveisPublicados.length} 
        despublicados={imoveisDespublicados.length}
      />)}
      { permissions.showCondominios && (<>
        <CardImovel001
          titulo={"Empreendimentos"} 
          publicados={empreendimentos.publicados.length}
          despublicados={empreendimentos.despublicados.length}
        />
        <CardImovel001 
          titulo={"Condomínios"} 
          publicados={condominios.publicados.length}
          despublicados={condominios.despublicados.length}
        />
      </>)}
    </Container>
  );
}
