import { Container, Section, ViewGrid } from "./styles";
import { CardTop001 } from "../../Components/Top10/CardTop001";
import { CardTop002 } from "../../Components/Top10/CardTop002";
import { CardTop003 } from "../../Components/Top10/CardTop003";
import { CardTop004 } from "../../Components/Top10/CardTop004";
import { useEffect, useState } from "react";
import dashboardStore from "../../store/dashboardStore";
import Header from "./Components/Header";
import LeadsGrid from "../../Components/Leads/LeadsGrid";
import ImoveisGrid from "../../Components/Imovel/ImoveisGrid";
import ImovelTab from "../../Components/TabCards/ImovelTab";
import { LoadingPage } from "../LoadingPage";
import { useSearchParams } from "../../hooks/useSearchParams";
import { externalRoutes } from "../../shared/externalRoutes";

export function Dashboard() {
  const { fetchDashboardInfo, imobiliaria: { isMidasWeb }, user: { permissions } } = dashboardStore();
  const { invalid, imobiliariaId, userId } = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [bodyHeight, setBodyHeight] = useState<number>(0);

  const atualizarAltura = () => {
    const altura = document.body.scrollHeight;
    setBodyHeight(altura);
    window.parent.postMessage({ altura: altura }, "*"); //Passando altura para fora do iframe
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarAltura);
    return () => {
      window.removeEventListener('resize', atualizarAltura);
    };
  }, []);

  useEffect(() => {
    if (imobiliariaId) {
        fetchDashboardInfo(imobiliariaId, userId)
        .then(() => {
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setTimeout(()=>{atualizarAltura()}, 300);
        });
    }
  }, [fetchDashboardInfo, imobiliariaId, userId]);

  // TODO: criar componente de error
  if (invalid) return <div>Error</div>

  if (loading) return <LoadingPage />;

  return (
    <Container>
      { permissions.showLeads && (<Section key={bodyHeight}>
        <Header titulo="Leads/Contatos" url={externalRoutes.leads} />
        <LeadsGrid />
      </Section>)}
      <Section>
        { (permissions.showAvulsos || permissions.showCondominios) && (<>
          <Header titulo="Imóveis" url={externalRoutes.imoveis} hideButton={isMidasWeb} />
          <ImoveisGrid />
        </>)}
        { permissions.showAvulsos && (<>
          <ImovelTab />
          <Header titulo="TOP 10 - Os mais visualizados no site" hideButton={true} />
          <ViewGrid>
              <CardTop001 />
              <CardTop002 />
          </ViewGrid>
        </>)} 
        { (permissions.showAvulsos || permissions.showCondominios) && (<>
          <Header titulo="Últimas atualizações" hideButton={true} />
          <ViewGrid>
              { permissions.showAvulsos && <CardTop003 />}
              { permissions.showCondominios && <CardTop004 />}
          </ViewGrid>
        </>)}
      </Section>
    </Container>
  );
}
