import { useEffect, useState } from 'react';
import { MD5 } from 'crypto-js';

export const useSearchParams = () => {
  const [invalid, setInvalid] = useState(false);
  const [imobiliariaId, setImobiliariaId] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const id = searchParams.get('imobiliaria_id') || '';
    const usuario = searchParams.get('usuario') || '';
    const hash = searchParams.get('hash') || '';

    if (!id || !usuario || !hash) {
      setInvalid(true);
      return;
    }

    const hashKey = process.env.REACT_APP_HASH_KEY;
    const hashString = id + usuario + hashKey;

    const hashCheck = MD5(hashString).toString();

    if (hashCheck !== hash) {
      setInvalid(true);
      return;
    }

    setImobiliariaId(id);
    setUserId(usuario);
  }, []);

  return { invalid, imobiliariaId, userId };
};
